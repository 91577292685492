/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DistributeStateEntity, DistributeUnit, OrgUnitEntity, StatisticItem, UserEntity } from 'models'

const initialState: DistributeStateEntity = {
  distributed_amount: 0,
  total_amount: 0,
  users: [],
  distributing: false,
  statistic_items: {}
}
const slice = createSlice({
  name: 'DISTRIBUTE_STATE',
  initialState,
  reducers: {
    setFocusStore: (state, { payload }: PayloadAction<OrgUnitEntity | undefined>) => {
      state.focus_store = payload
    },
    addStatistic: (state, { payload }: PayloadAction<StatisticItem[]>) => {
      payload.forEach((item) => {
        state.statistic_items[item.id] = item
      })
    },
    setRefresh: (state, { payload }: PayloadAction<boolean>) => {
      state.refresh = payload
    },
    setOnlyShowDistributeUnits: (state, { payload }: PayloadAction<boolean>) => {
      state.only_show_distributed_units = payload
    },
    setTotalDistributeAmount: (state, { payload }: PayloadAction<number>) => {
      state.total_amount = payload
    },
    changeUnitValue: (state, { payload }: PayloadAction<{ id: string; value: number }>) => {
      const unitIndex = state.users.findIndex((item) => item.id === payload.id)
      if (unitIndex == -1) {
        return
      }
      const sum = payload.value + state.users.reduce((sum, a) => (sum += a.id === payload.id ? 0 : a.value), 0)
      if (sum > state.total_amount) {
        return
      }
      state.distributed_amount = state.distributed_amount - state.users[unitIndex].value + payload.value
      state.users[unitIndex].value = payload.value
    },
    initUnits: (state, { payload }: PayloadAction<{ units: UserEntity[] }>) => {
      const arr: DistributeUnit[] = payload.units.map((item) => ({
        id: item.id,
        info: item,
        value: 0
      }))
      state.users = arr
      state.distributed_amount = state.total_amount
    },
    completeDistribute: (state) => {
      state.distributing = false
    },
    cancelDistribute: (state) => {
      state.distributing = false
      state.distributed_amount = state.total_amount
      state.users.forEach((_, index) => (state.users[index].value = 0))
    },
    startDistibute: (state) => {
      state.distributed_amount = 0
      state.distributing = true
      state.users.forEach((item, index) => {
        state.users[index].value = 0
      })
    },
    distributeEqually: (state) => {
      const unitCount = state.users.length
      const unitValue = Math.floor(state.total_amount / unitCount)
      state.total_amount = state.total_amount
      state.users.forEach((item, index) => {
        state.users[index].value = unitValue
      })
      var distributed = unitCount * unitValue
      var i = 0
      while (distributed < state.total_amount && i < unitCount) {
        state.users[i].value++
        distributed++
        i++
      }
      state.distributed_amount = state.total_amount
      state.distributing = true
    }
  }
})

export const { actions: distributeActions, reducer: distributeReducer } = slice
