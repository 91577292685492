import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const update = (id: string, data: ApiRequest.campaign.update) => instanceAxios.patch(`/campaigns/${id}`, data)

const assign_single = (id: string, data: ApiRequest.campaign.assign_single) => instanceAxios.post(`/campaigns/${id}/assign/carer`, data)
const assign_multiple = (id: string, data: ApiRequest.campaign.assign_multiple) => instanceAxios.post(`/campaigns/${id}/assign/carers`, data)
const show = async (id: string): Promise<any> => instanceAxios.get(`/campaigns/${id}`)

const employee_distribute = (id: string, data: ApiRequest.campaign.employee_distribute): Promise<any> =>
  instanceAxios.post(`/campaigns/${id}/distribute/employees`, data)
const employee_regain = (id: string, data: ApiRequest.campaign.employee_regain): Promise<any> => instanceAxios.post(`/campaigns/${id}/regain/employee`, data)
export default {
  update,
  show,
  assign: {
    single: assign_single,
    multiple: assign_multiple
  },
  distribute: {
    employee: employee_distribute
  },
  regain: {
    employee: employee_regain
  }
}
