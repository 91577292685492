import React, { useRef, useState } from 'react'

import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { DialogMain, MyTextField, UiAvatar, UIDialogRef } from 'components'
import { PermissionList } from 'pages/permission/detail/permission_list'
import { useSelector } from 'hooks'
import { COLORS, createSlug } from 'utils'

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

export function Profile() {
  const { user } = useSelector((x) => x.profile)
  const [searchKey, setSearchKey] = useState<string>('')
  const ref = useRef<UIDialogRef | null>()
  if (!user) {
    return <div />
  }

  var store_units = [...(user.store_units ?? [])]
  store_units.sort((u, v) => (u.name < v.name ? -1 : 1))
  const { permissions } = user

  const displayUnits = store_units.filter((u) => searchKey.length === 0 || createSlug([u.name]).includes(createSlug([searchKey])))
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <UiAvatar uri={user?.avatar} style={{ width: 36, height: 36 }} />
      <Stack direction="row" alignItems="flex-start" spacing={0.5} sx={{ ml: 3 }}>
        <Stack direction="column">
          <Tooltip title="Chỉnh sửa thông tin">
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '16px',
                color: '#333',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              {user?.name}
            </Typography>
          </Tooltip>
          <div onClick={() => ref?.current?.show()}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                color: COLORS.CelticBlue,
                textDecorationLine: 'underline',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              {'Xem quyền của tôi'}
            </Typography>
          </div>
        </Stack>
      </Stack>
      <DialogMain ref={ref} label="" onClose={() => ref?.current?.hide()}>
        <Stack>
          <Stack style={{}}>
            {store_units.length > 10 && (
              <MyTextField
                value={searchKey}
                width="400px"
                placeholder={`Tìm trong ${store_units.length} cửa hàng`}
                style={{ marginBottom: '10px' }}
                handleChange={setSearchKey}
              />
            )}

            <Grid container sx={{ maxHeight: '100px', overflowY: 'scroll' }}>
              {displayUnits.map(({ name }) => (
                <Grid item xs={2}>
                  <Box>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: COLORS.DarkCharcoal
                      }}
                    >
                      {name.replace(/Tokyolife/gi, 'T ').replace(/Format/gi, 'F ')}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Stack>
          <PermissionList data={permissions} />
        </Stack>
      </DialogMain>
    </Stack>
  )
}
